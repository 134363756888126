/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "44bits 팟캐스트의 전신 ", React.createElement(_components.a, {
    href: "https://www.youtube.com/@44bits-archived56/videos"
  }, "stdout.fm")), "\n", React.createElement(_components.li, null, "기억에 남는 에피소드", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=QcEsJndSI7c"
  }, "re:Invent 첫 방문기 1"), ", ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=JO3ffEZxWZU"
  }, "re:Invent 첫 방문기 2")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://youtu.be/ofDI6Doiqjk?feature=shared"
  }, "stdout.fm 첫 방송")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://podcast.44bits.io/episodes/67"
  }, "스톡옵션 이야기했던 67화")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://youtu.be/CI8K8YINTO8?feature=shared"
  }, "100회 특집")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://sduck4.github.io/stdout.fm-100th/"
  }, "100회 특집 축전 사이트")), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aheui.readthedocs.io/ko/latest/introduction.html"
  }, "아희"), " 아시나요?", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%82%9C%ED%95%B4%ED%95%9C_%ED%94%84%EB%A1%9C%EA%B7%B8%EB%9E%98%EB%B0%8D_%EC%96%B8%EC%96%B4"
  }, "Esoteric programming language, 난해한 프로그래밍 언어")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://web.archive.org/web/20150618184706/http://compsoc.dur.ac.uk/whitespace/tutorial.php"
  }, "Whitespace")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://esolangs.org/wiki/Brainfuck"
  }, "Brainfuck")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "좋아하는 테크 컨퍼런스", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://2023.pycon.kr/"
  }, "파이콘")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer.apple.com/kr/wwdc23/"
  }, "WWDC")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.withgoogle.com/next"
  }, "Google Cloud Next")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.outsider.ne.kr/813"
  }, "Outsider의 Nodeconf 2012 참석기")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://reinvent.awsevents.com/"
  }, "re:Invent")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devday.openai.com/"
  }, "OpenAI Developer Day")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.vidcon.com/"
  }, "VidCon")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "44bits의 의미", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://xkcd.com/936/"
  }, "Password Strength")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "AI와 소프트웨어 엔지니어", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.newyorker.com/tech/annals-of-technology/chatgpt-is-a-blurry-jpeg-of-the-web"
  }, "ChatGPT Is a Blurry JPEG of the Web / 테드 창")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "멤버들의 인생 책", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "후얌얌님 - ", React.createElement(_components.a, {
    href: "https://www.yes24.com/Product/Goods/8759796"
  }, "모순"), " / ", React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/m/mproduct.aspx?ItemId=26458212"
  }, "페르시아의 왕자 : 개발일지")), "\n", React.createElement(_components.li, null, "낙욧님 - ", React.createElement(_components.a, {
    href: "https://www.yes24.com/Product/Goods/9021653"
  }, "아마존 웹 서비스 클라우드 디자인 패턴 설계 가이드")), "\n", React.createElement(_components.li, null, "윤 - ", React.createElement(_components.a, {
    href: "https://m.yes24.com/Goods/Detail/117843256"
  }, "머신러닝 시스템 설계")), "\n", React.createElement(_components.li, null, "아웃 사이더님 - ", React.createElement(_components.a, {
    href: "https://m.yes24.com/Goods/Detail/124110681"
  }, "코드: 하드웨어와 소프트웨어에 숨어 있는 언어")), "\n", React.createElement(_components.li, null, "서비큐라님 & 시피님 - ", React.createElement(_components.a, {
    href: "https://m.yes24.com/Goods/Detail/1469763"
  }, "조엘 온 소프트웨어")), "\n", React.createElement(_components.li, null, "시피 - ", React.createElement(_components.a, {
    href: "https://m.yes24.com/Goods/Detail/1669603"
  }, "Just For Fun")), "\n", React.createElement(_components.li, null, "너굴님 - ", React.createElement(_components.a, {
    href: "https://m.yes24.com/Goods/Detail/3348853"
  }, "쉽고 빠른 웹 개발 Django 장고(절판)"), " / ", React.createElement(_components.a, {
    href: "https://www.yes24.com/Product/Goods/58515501"
  }, "은하영웅전설")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "기억에 남는 기술 뉴스", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%95%8C%ED%8C%8C%EA%B3%A0_%EB%8C%80_%EC%9D%B4%EC%84%B8%EB%8F%8C"
  }, "알파고와 이세돌의 경기")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/MicrosoftEdge/MSEdge"
  }, "Microsoft Edge and Chromium Open Source: Our Intent")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=VQKMoT-6XSg"
  }, "첫 아이폰 출시")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=OIV6peKMj9M"
  }, "첫 맥북에어 출시")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=wW9CAH9nSLs"
  }, "Docker 첫 발표")), "\n"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
